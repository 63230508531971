var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-sheet",
    { attrs: { outlined: "", rounded: "" } },
    [
      !_vm.loading
        ? _c("v-simple-table", {
            staticClass: "table-bordered table-striped",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { staticClass: "text-left font-size-h6" }, [
                            _vm._v("Ders Grubu"),
                          ]),
                          _c(
                            "th",
                            { staticClass: "text-center font-size-h6" },
                            [_vm._v("Soru Sayısı")]
                          ),
                          _c(
                            "th",
                            { staticClass: "text-center font-size-h6" },
                            [_vm._v("Doğru Sayısı")]
                          ),
                          _c(
                            "th",
                            { staticClass: "text-center font-size-h6" },
                            [_vm._v("Yanlış Sayısı")]
                          ),
                          _c(
                            "th",
                            { staticClass: "text-center font-size-h6" },
                            [_vm._v("Boş Sayısı")]
                          ),
                          _c(
                            "th",
                            { staticClass: "text-center font-size-h6" },
                            [_vm._v("Net")]
                          ),
                          _c(
                            "th",
                            { staticClass: "text-center font-size-h6" },
                            [_vm._v("Net Yüzdesi")]
                          ),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm._l(_vm.rows, function (row, idx) {
                            return _c("tr", { key: idx }, [
                              _c("td", { staticClass: "font-size-h6" }, [
                                _vm._v(_vm._s(row.ExamResultLessonGroupName)),
                              ]),
                              _c(
                                "td",
                                { staticClass: "text-center font-size-h6" },
                                [_vm._v(_vm._s(row.QuestionCount))]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-center font-size-h6" },
                                [_vm._v(_vm._s(row.CorrectAnswerCount))]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-center font-size-h6" },
                                [_vm._v(_vm._s(row.WrongAnswerCount))]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-center font-size-h6" },
                                [_vm._v(_vm._s(row.EmptyAnswerCount))]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-center font-size-h6" },
                                [_vm._v(_vm._s(_vm._f("toNumber")(row.Net)))]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-center font-size-h6" },
                                [
                                  _vm._v(
                                    "%" +
                                      _vm._s(
                                        _vm._f("toNumber")(row.NetPercentage)
                                      )
                                  ),
                                ]
                              ),
                            ])
                          }),
                          _c("tr", [
                            _c(
                              "td",
                              { staticClass: "text-left font-size-h6" },
                              [_c("strong", [_vm._v("TOPLAM")])]
                            ),
                            _c(
                              "td",
                              { staticClass: "text-center font-size-h6" },
                              [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.rows.reduce(
                                        (acc, row) => acc + row.QuestionCount,
                                        0
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "text-center font-size-h6" },
                              [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.rows.reduce(
                                        (acc, row) =>
                                          acc + row.CorrectAnswerCount,
                                        0
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "text-center font-size-h6" },
                              [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.rows.reduce(
                                        (acc, row) =>
                                          acc + row.WrongAnswerCount,
                                        0
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "text-center font-size-h6" },
                              [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.rows.reduce(
                                        (acc, row) =>
                                          acc + row.EmptyAnswerCount,
                                        0
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "text-center font-size-h6" },
                              [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("toNumber")(
                                        _vm.rows.reduce(
                                          (acc, row) => acc + row.Net,
                                          0
                                        )
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "text-center font-size-h6" },
                              [
                                _c("strong", [
                                  _vm._v(
                                    "%" +
                                      _vm._s(
                                        _vm._f("toNumber")(
                                          (_vm.rows.reduce(
                                            (acc, row) => acc + row.Net,
                                            0
                                          ) /
                                            _vm.rows.reduce(
                                              (acc, row) =>
                                                acc + row.QuestionCount,
                                              0
                                            )) *
                                            100
                                        )
                                      )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ],
                        2
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4000428831
            ),
          })
        : _c(
            "div",
            { staticClass: "d-flex justify-content-center" },
            [
              _c("v-progress-circular", {
                staticClass: "m-5",
                attrs: { indeterminate: "", color: "deep-orange" },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }