var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.filteredrows,
          "hide-default-footer": true,
          "disable-pagination": "",
          loading: _vm.loading,
          "mobile-breakpoint": "0",
          "show-expand": "",
          "item-key": "CustomId",
          "single-expand": "",
          "disabe-sort": "",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "expanded-item",
              fn: function ({ item, headers }) {
                return [
                  _c(
                    "td",
                    {
                      staticStyle: { padding: "0" },
                      attrs: { colspan: headers.length },
                    },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c("SubjectReportListByStudentAndAchievement", {
                            attrs: {
                              ExamIds: _vm.exams.map((exam) => exam.Id),
                              GroupingPropertyIds: [item.SubjectId],
                              GroupingPropertyName: _vm.GroupingPropertyName,
                              "assessment-point-calculation-type-id":
                                item.AssessmentPointCalculationTypeId,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "top",
              fn: function () {
                return [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "" } },
                    [
                      _c("v-select", {
                        staticClass: "mt-3",
                        attrs: {
                          "item-text": (item) => item.Name,
                          "item-value": (item) => item.Id,
                          items: _vm.lessons,
                          label: "Ders",
                          clearable: "",
                          "hide-details": "",
                          outlined: "",
                          dense: "",
                        },
                        on: { change: _vm.getDataFromApi },
                        model: {
                          value: _vm.LessonId,
                          callback: function ($$v) {
                            _vm.LessonId = $$v
                          },
                          expression: "LessonId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "" } },
                    [
                      _c("v-range-slider", {
                        staticClass: "mt-5",
                        attrs: {
                          label: "Konu Başarı Yüzdesi",
                          max: "100",
                          min: "0",
                          "thumb-size": 20,
                          "thumb-label": "always",
                          step: "1",
                        },
                        model: {
                          value: _vm.scyRange,
                          callback: function ($$v) {
                            _vm.scyRange = $$v
                          },
                          expression: "scyRange",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: `item.SuccessRate`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-progress-linear",
                    {
                      attrs: {
                        color: _vm.getColor(item.SuccessRate),
                        value: item.SuccessRate > 0 ? item.SuccessRate : 0,
                        height: "25",
                        readonly: "",
                        rounded: "",
                      },
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            Math.ceil(
                              item.SuccessRate > 0 ? item.SuccessRate : 0
                            )
                          ) + "%"
                        ),
                      ]),
                    ]
                  ),
                ]
              },
            },
            {
              key: `item.QuestionCount`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        item.CorrectAnswerCount +
                          item.WrongAnswerCount +
                          item.EmptyAnswerCount
                      ) +
                      " "
                  ),
                ]
              },
            },
            {
              key: `item.HomeworkQuestionCount`,
              fn: function ({ item }) {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _vm.loadingHomework
                        ? _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "primary" },
                          })
                        : _vm._e(),
                      item.TestCount > 0
                        ? _c(
                            "span",
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    bottom: "",
                                    color: _vm.getColor(
                                      (item.CompletedTestCount /
                                        item.TestCount) *
                                        100
                                    ),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-progress-linear",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticStyle: {
                                                      "min-width": "50px",
                                                    },
                                                    attrs: {
                                                      color: _vm.getColor(
                                                        (item.CompletedTestCount /
                                                          item.TestCount) *
                                                          100
                                                      ),
                                                      value:
                                                        (item.CompletedTestCount /
                                                          item.TestCount) *
                                                        100,
                                                      height: "25",
                                                      readonly: "",
                                                      rounded: "",
                                                    },
                                                  },
                                                  "v-progress-linear",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      Math.ceil(
                                                        (item.CompletedTestCount /
                                                          item.TestCount) *
                                                          100
                                                      )
                                                    ) + "% "
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        target: "_blank",
                                                        href:
                                                          "/odev-karnesi/" +
                                                          "?LessonId=" +
                                                          item.LessonId +
                                                          "&SubjectId=" +
                                                          item.SubjectId +
                                                          "&ParentSubjectId=" +
                                                          item.ParentSubjectId,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "b-tooltip",
                                                              rawName:
                                                                "v-b-tooltip.hover.right",
                                                              value:
                                                                "Ödev Karnesine Git",
                                                              expression:
                                                                "'Ödev Karnesine Git'",
                                                              modifiers: {
                                                                hover: true,
                                                                right: true,
                                                              },
                                                            },
                                                          ],
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-arrow-right-bold "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "black" } },
                                    [
                                      _vm._v(
                                        "İşaretlenen soru sayısı: " +
                                          _vm._s(item.CompletedQuestionCount) +
                                          " "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " İşaretlenmeyen soru sayısı: " +
                                          _vm._s(
                                            item.NotCompletedQuestionCount
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.HomeworkSCY`,
              fn: function ({ item }) {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _vm.loadingHomework
                        ? _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "primary" },
                          })
                        : _vm._e(),
                      item.SCY
                        ? _c(
                            "span",
                            [
                              _c(
                                "v-progress-linear",
                                {
                                  attrs: {
                                    color: _vm.getColor(item.SCY),
                                    value: item.SCY,
                                    height: "25",
                                    readonly: "",
                                    rounded: "",
                                  },
                                },
                                [
                                  _c("strong", [
                                    _vm._v(_vm._s(Math.ceil(item.SCY)) + "%"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "no-data",
              fn: function () {
                return [_c("span", [_vm._v("VERİ BULUNAMADI")])]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }