var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    staticClass: "elevation-1 blue-grey lighten-4",
    attrs: {
      headers: _vm.headers,
      items: _vm.rows,
      "server-items-length": _vm.rows.length,
      "hide-default-footer": "",
      loading: _vm.loading,
      "item-key": "StudentId",
      "mobile-breakpoint": "0",
    },
    scopedSlots: _vm._u(
      [
        {
          key: `item.Achievement`,
          fn: function ({ item }) {
            return [
              item.AchievementName != null
                ? _c("span", [_vm._v(_vm._s(item.AchievementName))])
                : _vm._e(),
            ]
          },
        },
        {
          key: `item.SelectedAnswer`,
          fn: function ({ item }) {
            return [
              _c(
                "v-chip",
                {
                  class: item.RealAnswer.includes(item.SelectedAnswer)
                    ? "success"
                    : item.SelectedAnswer == " "
                    ? "none"
                    : "error",
                  attrs: { small: "" },
                },
                [
                  _vm._v(
                    _vm._s(
                      item.SelectedAnswer == " " ? "?" : item.SelectedAnswer
                    ) + " "
                  ),
                ]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }